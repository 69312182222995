<template>
  <v-list-item class="pa-0">
    <v-list-item-avatar size="75">
      <base-img
        cover
        class="rounded-lg"
        max-width="5rem"
        :aspect-ratio="1"
        :src="aggregate.image || require('@/assets/no-image.png')"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-row gutters>
        <v-col cols="12" sm="7">
          <h4>{{ aggregate.name }}</h4>
          <v-list-item-subtitle class="primary--text text--lighten-1">
            +{{ aggregate.price }} €
          </v-list-item-subtitle>
        </v-col>
        <v-col cols="12" sm="5">
          <div class="actions accent rounded-xl ml-auto">
            <v-btn
              x-small
              plain
              fab
              color="transparent"
              elevation="0"
              @click="subQty"
              :disabled="aggregate.qty === 0"
            >
              <v-icon color="secondary">mdi-minus</v-icon>
            </v-btn>
            <span class="px-4">{{ aggregate.qty }}</span>
            <v-btn
              @click="addQty"
              color="transparent"
              x-small
              fab
              elevation="0"
            >
              <v-icon color="secondary">mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { aggregatesStoreActions } from "@/store/modules/aggregates/constNames";

export default {
  props: ["aggregate"],
  created() {
    this.aggregate.qty = 0;
    this.aggregate.type = "agregado";
  },
  methods: {
    addQty() {
      this.$store.dispatch(aggregatesStoreActions.ADD_QTY, this.aggregate);
    },
    subQty() {
      this.$store.dispatch(aggregatesStoreActions.SUB_QTY, this.aggregate);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 10px 0;
  width: 100%;
  max-width: max-content;
  min-width: 70px;
}
</style>
